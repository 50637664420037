import { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'
import { usePath } from '../hooks/usePath'

const SideNavigation = () => {
    const location = useLocation()
    const [active, setActive] = useState('')
    const { Forward } = usePath()
    useEffect(() => {
        setActive(location.pathname)
    }, [location])
    return (
        <nav id="side-navigation">
            <div className="menu">
                <ul>
                    <li className={active === '/about' ? 'active' : ''}>
                        <Forward to="/about">회사소개</Forward>
                    </li>
                    <li className={active === '/service' ? 'active' : ''}>
                        <Forward to="/service">서비스</Forward>
                    </li>
                    <li className={active === '/portfolio' ? 'active' : ''}>
                        <Forward to="/portfolio">포트폴리오</Forward>
                    </li>
                    <li className={active === '/contact' ? 'active' : ''}>
                        <Forward to="/contact">상담/견적</Forward>
                    </li>
                </ul>
                <div>
                    <dl>
                        <dd>
                            <span>T</span>
                            051-816-1470
                        </dd>
                        <dd>
                            <span>H</span>010-5842-3470
                        </dd>
                        <dd>
                            <span>E</span>cs@kksolution.co.kr
                        </dd>
                    </dl>
                    <dl>
                        <dd>월-금 09:30 - 18:30</dd>
                        <dd>주말 및 공휴일 휴무</dd>
                    </dl>
                    <dl>
                        <dd>
                            부산시 부산진구 서면로 10 <br /> 서면데시앙스튜디오 1층 114호
                        </dd>
                    </dl>
                </div>
            </div>
        </nav>
    )
}

export default SideNavigation
