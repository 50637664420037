import { FC, Fragment, HTMLProps, useContext, useEffect, useState } from 'react'
import { createContext } from 'react'
import { ModalOptions } from '../types/global'

import Cookies from 'js-cookie'
import tempPortfolioList from '../json/tempPortfolios.json'
import tempParnersList from '../json/tempPartners.json'
import tempCertificationList from '../json/tempCertifications.json'
import Loading from '../components/Loading'
import Modal from '../components/Modal'

type Theme = 'light' | 'dark' | 'auto'
type Language = 'ko' | 'en'

type GlobalDataType = {
    portPolio: any[]
    certification: any[]
    partner: any[]
}

type GlobalContextProps = {
    language: Language
    theme: Theme
    modalHandler: (modal: ModalOptions | null) => void
    globalData: GlobalDataType
    width: number
}
const GlobalContext = createContext<GlobalContextProps>({
    language: 'ko',
    theme: 'light',
    modalHandler: () => {},
    globalData: { portPolio: [], certification: [], partner: [] },
    width: window.innerWidth,
})

export const GlobalProvider: FC<HTMLProps<any>> = ({ ...props }) => {
    const [language, setLanguage] = useState<Language>((Cookies.get('language') as Language) ?? 'ko')
    const [theme, setTheme] = useState<Theme>((Cookies.get('theme') as Theme) ?? 'auto')

    const [activeMoadal, setActiveModal] = useState<ModalOptions | null>(null)
    const modalHandler = (options: ModalOptions | null) => {
        if (options === null) {
            document.body.classList.remove('active-modal')
        } else {
            document.body.classList.add('active-modal')
        }
        setActiveModal(options)
    }

    const [width, setWidth] = useState<number>(window.innerWidth)
    useEffect(() => {
        const resizeEvent = () => {
            setWidth(window.innerWidth)
        }
        window.addEventListener('resize', resizeEvent)
        return () => {
            window.removeEventListener('resize', resizeEvent)
        }
    }, [])
    const [loading, _setLoading] = useState<boolean>(true)

    const [globalData, _setGlobalData] = useState<GlobalDataType>({
        portPolio: [],
        certification: [],
        partner: [],
    })

    //기본 설정값 세팅
    useEffect(() => {
        const dataSet = async () => {
            await new Promise((resolve) => setTimeout(resolve, 1500))
            _setGlobalData({
                portPolio: tempPortfolioList,
                partner: tempParnersList,
                certification: tempCertificationList,
            })
            _setLoading(false)
        }
        dataSet()
    }, [])

    return (
        <GlobalContext.Provider
            value={{
                language: language,
                theme: theme,
                modalHandler: modalHandler,
                globalData: globalData,
                width: width,
            }}
        >
            <Loading loading={loading} />
            <Fragment>
                {props.children}
                {activeMoadal && (
                    <Modal
                        option={activeMoadal}
                        closeHandler={() => {
                            setActiveModal(null)
                        }}
                    />
                )}
            </Fragment>
        </GlobalContext.Provider>
    )
}

export const useGlobalContext = () => {
    return useContext(GlobalContext)
}
