import { useEffect, useRef, useState } from 'react'
import { usePath } from '../hooks/usePath'

const Header = () => {
    const activeSideMenu = () => {
        document.body.classList.toggle('active-side')
        document.body.classList.toggle('active-menu')
    }
    const headerRef = useRef<HTMLElement>(null)
    const [headerClass, setHeaderClass] = useState<string>('')
    const { forward } = usePath()
    const toMainHandler = () => {
        forward('/')
    }
    useEffect(() => {
        const fixHeader = () => {
            if (headerRef.current) {
                setHeaderClass(window.scrollY >= headerRef.current.clientHeight ? 'fixed' : '')
            }
        }
        window.addEventListener('scroll', fixHeader)

        return () => {
            window.removeEventListener('scroll', fixHeader)
        }
    }, [window.scrollY])
    return (
        <header className={headerClass} ref={headerRef}>
            <button onClick={toMainHandler} className="logo">
                <img src={require('../resources/images/logo_default_mix.png')} alt="kksolution" />
                <img src={require('../resources/images/logo_default.png')} alt="kksolution" className="light" />
            </button>

            <span onClick={activeSideMenu} className="button">
                <i></i>
                <span className="hamburger">
                    <span />
                </span>
                <div className="button-bg" />
            </span>
        </header>
    )
}

export default Header
