import { useState } from 'react'

import { makeQueryString } from '../utils/Helper'

function useFormData<T>(defaultFormData: T): {
    formData: T
    initFormData: (callback?: (data?: any) => void) => void
    changeHandler: (e: React.ChangeEvent<any>) => void
    updateFormData: (data: T) => void
} {
    const [formData, setFormdata] = useState<T>(defaultFormData)

    const changeHandler = (e: React.ChangeEvent<any>) => {
        if (e.target.type === 'checkbox') {
            setFormdata({ ...formData, [e.target.name]: e.target.checked })
        } else {
            setFormdata({ ...formData, [e.target.name]: e.target.value })
        }
    }
    const initFormData = (callback?: (data?: any) => void) => {
        setFormdata(defaultFormData)
        if (callback) {
            callback(defaultFormData)
        }
    }
    const updateFormData = (data: T) => {
        setFormdata(data)
    }

    return {
        formData,
        initFormData,
        changeHandler,
        updateFormData,
    }
}

export default useFormData
