import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { useEffect } from 'react'

import 'swiper/css'
import 'aos/dist/aos.css'

import './stylesheets/reset.css'
import './stylesheets/root.css'
import './stylesheets/common.css'
import './stylesheets/animation.css'
import './stylesheets/style.css'

import { GlobalProvider } from './providers/GlobalProvider'
import Aos from 'aos'

import AppRouter from './AppRouter'

const App = () => {
    useEffect(() => {
        Aos.init({ duration: 1000, delay: 0, offset: 0 })
        const blockContextMenu = (e: MouseEvent) => {
            console.log('blockContextMenu set')
            e.preventDefault()
        }
        const blockShortKey = (e: KeyboardEvent) => {
            console.log('blockShortKey set')
            if (
                e.key === 'F12' ||
                (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'C' || e.key === 'J')) ||
                (e.ctrlKey && e.key === 'U')
            ) {
                e.preventDefault()
            }
        }
        window.addEventListener('contextmenu', blockContextMenu)
        window.addEventListener('keydown', blockShortKey)
        return () => {
            Aos.refresh()
            window.removeEventListener('contextmenu', blockContextMenu)
            window.removeEventListener('keydown', blockShortKey)
        }
    }, [])

    return (
        <GlobalProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<AppRouter />} />
                </Routes>
            </BrowserRouter>
        </GlobalProvider>
    )
}

export default App
