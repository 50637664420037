import { StaticMap } from 'react-kakao-maps-sdk'
import circle1Image from '../resources/images/bg_circle1.png'
import circle2Image from '../resources/images/bg_circle2.png'
import macImage from '../resources/images/imac_divice.png'
import rollingImage from '../resources/images/rolling.jpg'
import imgSrc from '../resources/icons/arrow.svg'
import { useNavigate } from 'react-router-dom'
import noImageSource from '../resources/images/no-image.png'
import businessItems from '../json/businessItems.json'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'

import Marquee from 'react-fast-marquee'
import { usePath } from '../hooks/usePath'
import { useGlobalContext } from '../providers/GlobalProvider'
import Layout from '../layouts/Layout'

const Home = () => {
    const { Forward } = usePath()
    const { globalData } = useGlobalContext()
    const { partner, portPolio, certification } = globalData
    const navigate = useNavigate()
    return (
        <Layout showQucikContact={true} showHeader={true}>
            <section id="main_1">
                <img src={circle1Image} alt="background" className="bg_circle circle_1" />
                <img src={circle2Image} alt="background" className="bg_circle circle_2" />
                <div className="center">
                    <div className="mainbanner_text">
                        <h1>
                            one day <span>or</span> day one
                        </h1>
                        <h1>
                            you <span>decide</span>
                        </h1>
                        <p>
                            web을 통한 새로운 경험
                            <br />그 중심에 케이투솔루션이 있습니다.
                        </p>
                    </div>
                    <div className="imac">
                        <div className="imac_device">
                            <img src={macImage} alt="imac divice" />
                            <div className="rolling_img">
                                <img src={rollingImage} alt="rolling" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="main_2">
                <div className="sticky-area">
                    <h1>Service</h1>
                </div>
                <div className="content-area">
                    <div className="s_tiltle_wrap">
                        <p className="s_title">
                            고객의 성공을 돕는
                            <br />
                            <span className="color">비즈니스 파트너</span>
                        </p>
                    </div>

                    <ol className="service_nav"></ol>
                    <Swiper
                        modules={[Navigation, Pagination, Autoplay]}
                        loop
                        centeredSlides={true}
                        slidesPerView={1}
                        draggable={false}
                        pagination={{
                            clickable: true,
                            el: '.service_nav',
                            bulletClass: 'bullet',
                            bulletActiveClass: 'active',

                            renderBullet: (index: number, className: string) => {
                                const itme = businessItems[index]
                                return `<li class='${className}'>${itme.title}</li>`
                            },
                        }}
                    >
                        {businessItems.map((businessItem, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div className="s_content_wrap">
                                        <div className="image">
                                            <img
                                                src={require(`../resources/images/main/${businessItem.image}`)}
                                                alt={businessItem.title}
                                            />
                                        </div>
                                        <div className="content">
                                            <p>{businessItem.content}</p>
                                            <ul data-aos="fade-up">
                                                {businessItem.items.map((item, _index) => {
                                                    return <li key={_index}>{item}</li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
                <div className="buttons-area">
                    <Forward to="/service">
                        More
                        <span className="arrow">
                            <img src={imgSrc} alt="arrow" />
                        </span>
                    </Forward>
                </div>
            </section>
            <section id="main_3">
                <div className="sticky-area">
                    <h1>Portfolio</h1>
                </div>
                <div className="content-area">
                    <ul className="portfolios">
                        {portPolio.slice(0, 6).map((item, index) => {
                            const portFolioClickHandler = () => {
                                navigate(`/portfolio/${item.id}`)
                            }
                            return (
                                <li key={index} onClick={portFolioClickHandler}>
                                    <div className="image-wrapper">
                                        {item.headerImage ? (
                                            <img src={item.thumbnailImage} alt={''} />
                                        ) : (
                                            <img src={noImageSource} alt="no-image" />
                                        )}
                                    </div>
                                    <div className="content-wrapper">
                                        <dl>
                                            <dt>{item.category}</dt>
                                            <dd>{item.name}</dd>
                                        </dl>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="buttons-area">
                    <Forward to="/portfolio">
                        More
                        <span className="arrow">
                            <img src={imgSrc} alt="arrow" />
                        </span>
                    </Forward>
                </div>
            </section>
            <section id="main_4">
                <div className="sticky-area">
                    <h1>Partners</h1>
                </div>
                <div className="content-area">
                    <div className="partner">
                        <Marquee className="marquee" speed={100}>
                            {partner.map((_, _i) => {
                                return (
                                    <div key={_i} className="marquee-item">
                                        <img src={_.logo} alt={_.name} />
                                    </div>
                                )
                            })}
                        </Marquee>
                    </div>
                </div>
            </section>
            <section id="main_certification">
                <div className="sticky-area">
                    <h1>certificate</h1>
                </div>
                <div className="content-area">
                    <div className="certification">
                        <Marquee className="marquee" speed={100}>
                            {certification.map((_, _i) => {
                                return (
                                    <div key={_i} className="marquee-item">
                                        <img src={_.image} />
                                        <span>{_.name}</span>
                                    </div>
                                )
                            })}
                        </Marquee>
                    </div>
                </div>
            </section>
            <section id="main_5">
                <div className="sticky-area">
                    <h1>Contact</h1>
                </div>

                <div className="wrapper logobg">
                    <div className="estimation ">
                        <p>케이투솔루션에게 프로젝트에 대해 알려주세요!</p>
                        <img
                            src={require('../resources/images/device.png')}
                            data-aos="flip-left"
                            data-aos-duration="1000"
                            alt="device"
                        />
                    </div>
                    <div className="buttons-area">
                        <Forward to="/contact">
                            More
                            <span className="arrow">
                                <img src={imgSrc} alt="arrow" />
                            </span>
                        </Forward>
                    </div>
                </div>

                <div className="info">
                    <div className="info_text_wrap">
                        <h5>
                            <img src={require('../resources/images/logo_large.png')} alt="logo" />
                        </h5>
                        <p>
                            <span>
                                <img src={require('../resources/icons/call_black.png')} alt="call number" />
                            </span>
                            <a href="tel:051-816-1470">051-816-1470</a>
                        </p>
                        <p>
                            <span>
                                <img src={require('../resources/icons/phone.png')} alt="phone number" />
                            </span>
                            <a href="tel://010-5842-3470">010-5842-3470</a>
                        </p>
                        <p>
                            <span>
                                <img src={require('../resources/icons/mail.png')} alt="mail" />
                            </span>
                            <a href="mailto://cs@kksolution.co.kr">cs@kksolution.co.kr</a>
                        </p>
                        <p>
                            <span>
                                <img src={require('../resources/icons/location.png')} alt="location" />
                            </span>
                            부산시 부산진구 서면로 10 서면 데시앙스튜디오 1층 114호
                        </p>
                    </div>
                    <StaticMap
                        id="map"
                        center={{ lat: 35.15133515051912, lng: 129.0583049181533 }}
                        level={5}
                        marker={{
                            position: { lat: 35.15133515051912, lng: 129.0583049181533 },
                            text: '케이투솔루션',
                        }}
                    ></StaticMap>
                </div>
            </section>
        </Layout>
    )
}

export default Home
