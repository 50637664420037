import { FC, HTMLProps, useEffect, useRef, useState } from 'react'
import { Link, LinkProps, To, useLocation, useNavigate } from 'react-router-dom'

export const usePath = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const transPathHandler = (path: To | number) => {
        if (path === pathname) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            })
            document.body.classList.remove('active-side')
            document.body.classList.remove('active-menu')
            return
        }

        document.body.classList.add('active-side')
        setTimeout(() => {
            document.body.classList.remove('active-side')
            document.body.classList.remove('active-menu')
        }, 300)
        setTimeout(() => {
            if (typeof path === 'number') {
                navigate(path)
            } else {
                navigate(path)
            }

            window.scrollTo({
                top: 0,
                left: 0,
            })
        }, 150)
    }
    const forward = (path: To | number) => {
        transPathHandler(path)
    }

    const Forward: FC<LinkProps> = ({ onClick, ...props }) => {
        return (
            <Link
                onClick={(e) => {
                    e.preventDefault()
                    forward(props.to)
                }}
                {...props}
            ></Link>
        )
    }
    return { forward, Forward }
}
