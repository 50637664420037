import { FC, useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import noImageSource from '../resources/images/no-image.png'
import PortfolioView from './PortfolioView'
import { usePath } from '../hooks/usePath'
import { useGlobalContext } from '../providers/GlobalProvider'
import Layout from '../layouts/Layout'

const PortfolioItem: FC<{ item: any }> = ({ item }) => {
    const navigate = useNavigate()
    const { forward } = usePath()
    const clickHandler = () => {
        forward(`/portfolio/${item.id}`)
    }
    return (
        <li data-aos="fade-up" onClick={clickHandler}>
            <div className="image-wrapper">
                {item.headerImage ? (
                    <img src={item.thumbnailImage} alt={''} />
                ) : (
                    <img src={noImageSource} alt="no-image" />
                )}
            </div>
            <div className="content-wrapper">
                <dl>
                    <dt>{item.category}</dt>
                    <dd>{item.name}</dd>
                </dl>
            </div>
        </li>
    )
}

const Portfolio = () => {
    const { globalData } = useGlobalContext()
    const { portPolio } = globalData

    const { id } = useParams()
    const [viewItem, setViewItem] = useState<any>(null)
    useEffect(() => {
        if (id) {
            const item = portPolio.find((_item, _index) => {
                return _item.id == id
            })
            if (item) {
                setViewItem(item)
            }
        } else {
            setViewItem(null)
        }
    }, [id, globalData])
    return viewItem ? (
        <PortfolioView item={viewItem} />
    ) : (
        <Layout showQucikContact={true} showHeader={true}>
            <section id="portfolio_1">
                <div className="sticky-area">
                    <h1 className="hide-scroll">portfolio</h1>
                    <img src={require('../resources/images/background_4.jpg')} alt="banner" className="show-scroll" />
                </div>
            </section>
            <section id="portfolio_2">
                <h1>
                    intuitive and satisfying <br />
                    digital experiences
                </h1>
                <div className="content-area">
                    <ul className="portfolios">
                        {portPolio?.map((item, index) => {
                            return <PortfolioItem item={item} key={index} />
                        })}
                    </ul>
                </div>
            </section>
        </Layout>
    )
}

export default Portfolio
