import { FC, useEffect, useState } from 'react'
import noImageSource from '../resources/images/no-image.png'
import iconLink from '../resources/icons/icon_link.svg'
import { useNavigate } from 'react-router-dom'
import { usePath } from '../hooks/usePath'
import Layout from '../layouts/Layout'

type Props = {
    item: any
}
const PortfolioView: FC<Props> = ({ item }) => {
    const navigate = useNavigate()
    const { forward } = usePath()
    const [loading, setLoading] = useState<boolean>(true)
    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
        setLoading(false)
    }, [])
    if (loading) {
        return <div className="loading-pannel"></div>
    }
    return (
        <Layout showQucikContact showHeader>
            <div className="portfolio-container">
                <div
                    className="button back"
                    onClick={() => {
                        forward(-1)
                    }}
                >
                    <img src={require('../resources/icons/icon_arrow.svg').default} />
                    <i>back</i>
                </div>

                <section className="portfolio-header">
                    <div
                        className="title"
                        data-aos="fade-up"
                        data-aos-duration="500"
                        style={{
                            // backgroundImage: item?.headerFiles[0] ? `url(${item?.headerFiles[0]?.url})` : noImageSource,
                            backgroundImage: item.headerImage ? `url(${item.headerImage})` : noImageSource,
                        }}
                    >
                        <div data-aos="fade-down" data-aos-delay="300">
                            <span>{item?.name}</span>
                            <span>{item?.category}</span>
                            {item?.url && (
                                <a href={item?.url} target="_blank">
                                    <i className="url">{item?.url}</i>
                                    <img src={iconLink} />
                                </a>
                            )}
                        </div>
                    </div>
                    <div className="overview" data-aos="fade-up" data-aos-delay="300">
                        <h5>overview</h5>
                        <p>{item?.overView}</p>
                    </div>
                </section>
                <section className="portfolio-body">
                    {item.bodyImage.map((url: any, index: number) => {
                        return (
                            <div className="image-wrapper" key={index}>
                                <img src={url} alt={url} data-aos="fade-up" />
                            </div>
                        )
                    })}
                </section>
                {/* <section className="portfolio-footer">
                <p>지금, 함께 새로운 시작.</p>
                <img src={logo} alt="logo" className="logo" />

                <div className="contact">
                    <dl>
                        <dt>
                            <img src={require('../resources/icons/mail.png')} />
                            email
                        </dt>
                        <dd>
                            <a href="mailto:cs@kksolution.co.kr">cs@kksolution.co.kr</a>
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <img src={require('../resources/icons/call_black.png')} />
                            tel
                        </dt>
                        <dd>
                            <a href="tel:051-816-1470">051-816-1470</a>
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <img src={require('../resources/icons/phone.png')} />
                            phone
                        </dt>
                        <dd>
                            <a href="tel:010-5842-3470">010-5842-3470</a>
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <img src={require('../resources/icons/location.png')} />
                            address
                        </dt>
                        <dd>부산시 부산진구 서면로 10 114호</dd>
                    </dl>
                </div>
            </section> */}
            </div>
        </Layout>
    )
}

export default PortfolioView
