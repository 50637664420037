import { Fragment, useEffect, useRef } from 'react'

import gsap from 'gsap'

import qaIcon from '../resources/icons/qna_w.png'
import tellIcon from '../resources/icons/call.png'
import kakaoIcon from '../resources/icons/kakao.png'
import { usePath } from '../hooks/usePath'
const RemoteController = () => {
    const { Forward } = usePath()
    const remoteController = useRef<HTMLSpanElement>(null)

    useEffect(() => {
        gsap.fromTo(
            remoteController.current,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                scrollTrigger: {
                    trigger: remoteController.current,
                    start: 'top center',
                    end: 'top 100px',
                    scrub: true,
                },
            },
        )
    }, [])
    return (
        <Fragment>
            {/* {positionY === 0 && <span className="mouse-scroll" />} */}
            <span className="remote-controller" ref={remoteController}>
                <Forward to="/contact" className="button">
                    <i>상담문의</i>
                    <img src={qaIcon} alt="상담문의" />
                </Forward>
                <a href="tel:051-816-1470" className="button" target="_self" rel="noreferrer">
                    <i>전화문의</i>
                    <img src={tellIcon} alt="전화문의" />
                </a>
                <a href="https://pf.kakao.com/_FwxdxmG" target="_blank" rel="noreferrer" className="button">
                    <i>카톡문의</i>
                    <img src={kakaoIcon} alt="카톡문의" />
                </a>
            </span>
        </Fragment>
    )
}

export default RemoteController
