import { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import About from './pages/About'
import Service from './pages/Service'
import Portfolio from './pages/Portfolio'
import Contact from './pages/Contact'
import Notice from './pages/Notice'
import NotFound from './pages/NotFound'
import Home from './pages/Home'
import Private from './pages/Private'

gsap.registerPlugin(ScrollTrigger)
const AppRouter = () => {
    const location = useLocation()
    useEffect(() => {
        document.querySelectorAll('.show-scroll').forEach((el) => {
            gsap.fromTo(
                el,
                { opacity: 0 },
                {
                    opacity: 1,
                    scrollTrigger: {
                        trigger: el,
                        start: 'top top',
                        end: '+=150vh',
                        scrub: true,
                    },
                },
            )
        })
        document.querySelectorAll('.hide-scroll').forEach((el) => {
            gsap.fromTo(
                el,
                { opacity: 1 },
                {
                    opacity: 0,
                    scrollTrigger: {
                        trigger: el,
                        start: 'top top',
                        end: '+=150vh',
                        scrub: true,
                    },
                },
            )
        })
    }, [location])
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/service" element={<Service />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/portfolio/:id" element={<Portfolio />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/notice" element={<Notice />} />
            <Route path="/privacy" element={<Private />} />
            <Route path="/*" element={<NotFound />} />
        </Routes>
    )
}

export default AppRouter
