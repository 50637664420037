import { FC, Fragment, HTMLProps, ReactNode, useContext, useEffect, useRef } from 'react'

import RemoteController from './RemoteController'
import Header from './Header'
import Footer from './Footer'
import SideNavigation from './SideNavigation'
type Props = {
    showQucikContact?: boolean
    showHeader?: boolean
}
const Layout: FC<Props & HTMLProps<any>> = ({ showQucikContact, showHeader, ...props }) => {
    return (
        <Fragment>
            {showHeader && <Header />}

            <main {...props} />

            <script type="text/javascript" src="//wcs.naver.net/wcslog.js"></script>
            <script type="text/javascript">var _nasa={}; if(window.wcs) _nasa["cnv"] = wcs.cnv("1","10");</script>

            <Footer showQucikContact={showQucikContact} />
            <SideNavigation />
            <RemoteController />
        </Fragment>
    )
}

export default Layout
