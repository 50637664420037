import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import ProcessItems from '../json/processItems.json'
import BusinessAreaItems from '../json/businessAreaItems.json'
import OperationItems from '../json/operationItems.json'
import { useEffect, useRef, useState } from 'react'
import { useGlobalContext } from '../providers/GlobalProvider'
import Layout from '../layouts/Layout'

const Service = () => {
    const { width } = useGlobalContext()

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('active')
                    } else {
                        entry.target.classList.remove('active')
                    }
                })
            },
            { threshold: 0 },
        )
        const services = document.querySelectorAll('.contents > div')
        services.forEach((el) => {
            observer.observe(el)
        })
        return () => {
            services.forEach((el) => {
                observer.unobserve(el)
            })
        }
    }, [])

    return (
        <Layout showQucikContact={true} showHeader={true}>
            <section id="service_1">
                <div className="sticky-area ">
                    <h1 className="hide-scroll">
                        one day <span className="error">or</span> day one
                        <p>You decide</p>
                    </h1>
                    <h2 className="show-scroll">
                        케이투솔루션만의 특별한 서비스. <span>그 시작은 여러분의 선택에 달려있습니다.</span>
                    </h2>
                    <img className="show-scroll" src={require('../resources/images/background_3.jpg')} alt="banner" />
                </div>
            </section>

            <section id="service_2">
                <div className="content-area">
                    <div className="s2_cen center-box" data-aos="fade-up">
                        <span className="category">
                            <span className="point_latter">S</span>
                            ervice
                        </span>
                        <div>
                            <h1 className="service_title">
                                성공적인 프로젝트 수행을 위해 최고의 기술력으로 최선을 다합니다.
                            </h1>
                            <p>고객의 성공을 돕는 비즈니스 파트너사로서의 역할을 충실히 수행합니다.</p>
                        </div>
                    </div>
                    <div className="service-wrap">
                        <div className="text">
                            <ul>
                                <li data-aos="zoom-in" data-aos-offset="100">
                                    <div className="service_img_wrap">
                                        <img src={require('../resources/images/sub/s_img_1.jpg')} alt="" />
                                    </div>
                                    <dl>
                                        <dt>01 고객에 대한 정확한 이해로부터 시작합니다.</dt>
                                        <dd>
                                            케이투솔루션은 단순한 요구사항에 대한 대응으로 그치는 것이 아니라 성공을
                                            위한 제안, 목적과 목표에 정확히 부합하는 컨셉을 바탕으로 최적화된 기획과
                                            설계를 통한 컨설팅을 제공합니다.
                                        </dd>
                                    </dl>
                                </li>
                                <li data-aos="zoom-in" data-aos-offset="200">
                                    <div className="service_img_wrap">
                                        <img src={require('../resources/images/sub/s_img_2.jpg')} alt="" />
                                    </div>
                                    <dl>
                                        <dt>02 즉각적인 대응과 피드백을 제공합니다.</dt>
                                        <dd>
                                            각 프로세스에서 발생하는 event에 대한 즉각적인 대응을 통해 고객이 안심 할 수
                                            있도록 안정적인 피드백을 제공합니다.
                                        </dd>
                                    </dl>
                                </li>
                                <li data-aos="zoom-in" data-aos-offset="300">
                                    <div className="service_img_wrap">
                                        <img src={require('../resources/images/sub/s_img_3.jpg')} alt="" />
                                    </div>
                                    <dl>
                                        <dt>03 오랜 기간의 경험과 최신 기술을 보유하고 있습니다.</dt>
                                        <dd>
                                            단순히 찍어내기만 하는 web, app 홈페이지가 아닌 각각의 고객의 특성에 맞는
                                            솔루션을 최신기술을 통해 제시합니다.
                                        </dd>
                                    </dl>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section id="service_3">
                <div className="content-area">
                    <div>
                        <span className="category">
                            <span className="point_latter">B</span>
                            usiness area
                        </span>
                        <h1 className="service_title">
                            기획, 디자인, 개발, 구축부터 운영까지 다양한 영역에서의 전문 서비스를 제공합니다.
                        </h1>
                        <div className="contents">
                            {BusinessAreaItems.map((content, index) => {
                                return (
                                    <div key={index}>
                                        <h6>{content.en}</h6>
                                        <h2>{content.ko}</h2>
                                        <div className="content">
                                            <p>{content.header}</p>
                                            <ul>
                                                {content.items.map((item, _index) => {
                                                    return <li key={_index}>{item}</li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>

            <section id="service_4">
                <div className="content-area">
                    <span className="category_black">
                        <span className="point_latter">P</span>
                        rocess
                    </span>
                    <h1 className="service_title">
                        체계적인 프로세스를 통해 <br />
                        높은 완성도의 결과물을 제작합니다.
                    </h1>
                    <div className="process-area">
                        <div className="process-pagination"></div>
                    </div>
                </div>

                <div className="slider-area">
                    <div className="slider">
                        <div className="prev">
                            <img src={require('../resources/images/prev_button.png')} alt="prev button" />
                        </div>

                        <div className="process_slider">
                            <Swiper
                                modules={[Navigation, Pagination, Autoplay]}
                                centeredSlides={true}
                                slidesPerView={width >= 1440 ? 3 : width >= 768 ? 2 : 1}
                                navigation={{
                                    nextEl: '.next',
                                    prevEl: '.prev',
                                    disabledClass: 'disabled',
                                }}
                                speed={1000}
                                pagination={{
                                    clickable: true,
                                    bulletActiveClass: 'on',
                                    bulletClass: 'bullet',
                                    el: '.process-pagination',
                                    renderBullet: (index: number, className: string) => {
                                        const itme = ProcessItems[index]
                                        return `
                                    <span class='${className}'>
                                        <span>
                                            ${itme.step}
                                            <p>${itme.title}</p>
                                        </span>
                                    </span>
                                    `
                                    },
                                }}
                                scrollbar={{ draggable: true }}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}
                            >
                                {ProcessItems.map((item, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <h6>{`STEP ${item.step} ${item.title}`}</h6>
                                            <h2>{item.header}</h2>
                                            {item.content.map((content, _index) => {
                                                return <p key={_index}>{content}</p>
                                            })}
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                        <div className="next">
                            <img src={require('../resources/images/next_button.png')} alt="next button" />
                        </div>
                    </div>
                </div>
            </section>
            <section id="service_5">
                <div className="content-area">
                    <div className="s4_center">
                        <span className="category">
                            <span className="point_latter">O</span>
                            peration
                        </span>

                        <h1 className="service_title">
                            케이투솔루션 자체 제작 관리 프로그램
                            <br />
                            직관적이고 편리한 운영 및 관리
                        </h1>
                        <p className="s4_text">
                            최소 5년이상 사용하게 되는 홈페이지/쇼핑몰의 경우 유지보수는 구축만큼이나 중요한 사항입니다.
                            <br />
                            즉각적인 대응과 업데이트가 가능하여야 하며 편리함과 더불어 해킹으로부터 안전해야합니다.
                        </p>
                        <ul className="s4_ul">
                            <li data-aos="fade-left" data-aos-offset="0">
                                <div className="iconimg">
                                    <img src={require('../resources/images/service_3d_1.png')} alt="" />
                                </div>
                                <div className="s4_content">
                                    <span>point 01</span>
                                    <h4>편리하고 보안에 강한 관리자모드</h4>
                                    <p>
                                        홈페이지를 보안에 취약한 오픈소스(제로보드,그누보드,워드프레스)로 구축 할 경우
                                        시간이 경과할수록 보안 문제가 발생할 확률이 높아집니다. 이를 방지하기 위해
                                        케이투솔루션은 자체 제작한 CMS로 강력한 보안 성능을 제공합니다
                                    </p>
                                </div>
                            </li>
                            <li data-aos="fade-left">
                                <div className="iconimg">
                                    <img src={require('../resources/images/service_3d_2.png')} alt="" />
                                </div>
                                <div className="s4_content">
                                    <span>point 02</span>
                                    <h4>메인배너관리</h4>
                                    <p>
                                        메인배너 사진을 고객사에서 원하는 것으로 언제든지 바꾸실 수 있도록 관리자
                                        모드에서 편리하게 제공합니다.
                                        <br />
                                        (EX.여행사 등 계절마다 다른 사진을 원하는 업체일 경우 등등)
                                    </p>
                                </div>
                            </li>
                            <li data-aos="fade-left">
                                <div className="iconimg">
                                    <img src={require('../resources/images/service_3d_3.png')} alt="" />
                                </div>
                                <div className="s4_content">
                                    <span>point 03</span>
                                    <h4>SEO 최적화를 통한 마케팅 효과 극대화</h4>
                                    <p>
                                        검색엔진 최적화를 통한 포털사이트 검색시 상위노출 유도하여 마케팅 효과를 극대화
                                        합니다. 필요시 무료상담 후 최적화 유료 마케팅 PLAN을 통해 홈페이지, 쇼핑몰 구축
                                        효과를 극대화 할 수도 있습니다.
                                    </p>
                                </div>
                            </li>
                            <li data-aos="fade-left">
                                <div className="iconimg">
                                    <img src={require('../resources/images/service_3d_4.png')} alt="" />
                                </div>
                                <div className="s4_content">
                                    <span>point 04</span>
                                    <h4>추가 개발을 염두한 설계</h4>
                                    <p>
                                        초기 개발 및 구축 이후 확장의 가능성을 염두하여 설계를 진행합니다. 2층 주택을
                                        짓고 끝나는 것이 아닌 언제든지 고객의 성장에 따른 빌딩을 올릴 수 있는 설계로
                                        홈페이지를 구축합니다.
                                    </p>
                                </div>
                            </li>
                            <li data-aos="fade-left">
                                <div className="iconimg">
                                    <img src={require('../resources/images/service_3d_5.png')} alt="" />
                                </div>
                                <div className="s4_content">
                                    <span>point 05</span>
                                    <h4>로그분석 솔루션</h4>
                                    <p>
                                        빅데이터 분석을 통한 웹 로그분석을 제공합니다. 홈페이지 사용자들의 접속 횟수,
                                        체류시간 등을 분석하여 개선을 위한 정보를 제공합니다.
                                    </p>
                                </div>
                            </li>
                            <li data-aos="fade-left">
                                <div className="iconimg">
                                    <img src={require('../resources/images/service_3d_6.png')} alt="" />
                                </div>
                                <div className="s4_content">
                                    <span>point 06</span>
                                    <h4>간편한 파일 UPLOAD 및 콘텐츠 관리</h4>
                                    <p>
                                        기존에 사용하시던 UX 를 최대한 살린 편리하고 직관적인 파일 업로드 및 콘텐츠 관리
                                        기능을 제공합니다.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section id="service_6">
                <div className="content-area">
                    <h1 className="service_title">
                        구축 및 운영에 필요한 <br />
                        다양한 서비스를 무상으로 제공합니다.
                    </h1>
                    <ul className="s5_ul">
                        {OperationItems.map((item, index) => {
                            return (
                                <li data-aos="flip-down" key={index}>
                                    <img src={require(`../resources/images/${item.src}`)} alt="" />
                                    <h5>{item.title}</h5>
                                    <p>{item.content}</p>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </section>
        </Layout>
    )
}

export default Service
