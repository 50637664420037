import { usePath } from '../hooks/usePath'
import Layout from '../layouts/Layout'

const About = () => {
    const { Forward } = usePath()

    return (
        <Layout showQucikContact={true} showHeader={true}>
            <section id="about_1">
                <div className="sticky-area">
                    <img
                        content="show-scroll"
                        src={require('../resources/images/background_2.jpg')}
                        alt="about"
                        className="show-scroll"
                    />
                    <h1 className="hide-scroll">About Us</h1>
                </div>
            </section>

            <section id="about_2">
                <div className="content-area">
                    <div data-aos="fade-right">
                        <div className="content">
                            <h3>WEB을 통한 새로운 경험</h3>
                            <h2>
                                그 중심에 <span className="active">케이투솔루션</span>이 있습니다
                            </h2>
                        </div>
                    </div>
                    <div data-aos="fade-left" className="img_reverse">
                        <img src={require('../resources/images/subicon2.png')} alt="run" />
                        <div className="content">
                            <h4>고객을 위한 길이라면…</h4>
                            <p>
                                케이투솔루션은 고객을 위한 길이라면 어떤 험한길도 헤쳐 나아갈 준비가 되어있습니다.
                                고객을 위한 배려, 노력, 혁신을 위해 헌신하겠습니다.
                            </p>
                        </div>
                    </div>
                    <div data-aos="fade-right" className="img_scale">
                        <img src={require('../resources/images/subicon1.png')} alt="good" />
                        <div className="content">
                            <h4>WEB, APP, AI, AR, Virtual reality, Machine learning</h4>
                            <p>
                                이런 어려운 단어는 모르셔도 됩니다. 케이투솔루션은 여러분이 믿어주신 만큼 새로운 경험과
                                성공적인 프로젝트로 보답합니다. 어제보다 더 나은 내일을 위해 항상 노력하겠습니다.
                            </p>
                        </div>
                    </div>
                </div>
                <section id="about_3">
                    <div className="content-area">
                        <h5>
                            <span className="point_latter">P</span>
                            rofessional team
                        </h5>
                        <h2>케이투솔루션의 모든 프로젝트는 전문가로 구성된 팀과 함께합니다.</h2>
                        <img src={require('../resources/images/sub/team.png')} alt="process" />
                    </div>
                </section>
            </section>

            <section id="about_4">
                <div className="content-area">
                    <div className="sub-title">
                        <h5>
                            <span className="point_latter">B</span>
                            usiness
                        </h5>
                    </div>
                    <div className="content">
                        <div data-aos="fade-left">
                            <h3>각 분야별 프로들로 구성된 전문가 조직</h3>
                            <p>
                                프로젝트를 세밀하고 완성도 있게 잘 수행 하기 위해선 정성과 노력도 필요하지만 무엇보다
                                실력이 있어야 합니다. 원하시는 기능, 디자인, 시스템이 있다면 말씀해주세요. 불가능한
                                개발은 없습니다.
                            </p>
                        </div>
                        <div data-aos="fade-left" data-aos-offset="200">
                            <h3>고객과의 인연을 끝까지</h3>
                            <p>
                                케이투솔루션은 홈페이지, 영상, 어플제작, SNS 마케팅 등 다양한 분야에서 고객과의 관계를
                                맺고 있습니다. 한 번 인연을 맺은 고객과는 같이 성공을 해야 한다는 생각을 갖고 있습니다.
                                어려운 시기이지만 함께 잘 이겨 내리라는 자신감을 갖고 있습니다.
                            </p>
                        </div>
                        <div data-aos="fade-left" data-aos-offset="300">
                            <h3>BUSINESS DIGITAL DESIGN SERVICE</h3>
                            <p>
                                케이투솔루션의 비즈니스 디자인은 브랜드의 관점에서 부터 시작합니다. 고객에게 전하고자
                                하는 가치를 컨셉화하고 정확하고 눈으로 볼 수 있는 명확한 산출물로 제공합니다. 철저한
                                기획, 기발한 창의력, 오랜 경험에서 나오는 해답들로 고객사의 만족을 이끌어냅니다
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about_5">
                <div className="content-area">
                    <div className="sub-title">
                        <h5>
                            <span className="point_latter">O</span>
                            ur partners
                        </h5>
                    </div>
                    <h6>
                        <span>고객 이상의 파트너 </span>
                        <br />
                        케이투솔루션은 고객의 성공과 함께 성장합니다.
                    </h6>
                    <h6>브랜드의 철학을 이해하고 분석해 최선의 결과물을 만들어냅니다.</h6>
                    <ul>
                        <li>
                            <img src="/_partner/donghwa.jpg" alt="partner_ex" />
                        </li>
                        <li>
                            <img src="/_partner/duandkong.jpg" alt="partner_ex" />
                        </li>
                        <li>
                            <img src="/_partner/hnp.jpg" alt="partner_ex" />
                        </li>
                        <li>
                            <img src="/_partner/infrak.jpg" alt="partner_ex" />
                        </li>

                        <li>
                            <img src="/_partner/js.jpg" alt="partner_ex" />
                        </li>
                        <li>
                            <img src="/_partner/nhn.jpg" alt="partner_ex" />
                        </li>

                        <li>
                            <img src="/_partner/kmong.jpg" alt="partner_ex" />
                        </li>
                        <li>
                            <img src="/_partner/winhitech.jpg" alt="partner_ex" />
                        </li>
                    </ul>
                    <Forward to="/portfolio">Portfolio</Forward>
                </div>
            </section>
            <section id="ci">
                <div className="content-area">
                    <div className="sub-title">
                        <h5>
                            <span className="point_latter">C</span>
                            orporate Identity
                        </h5>
                        <h6>케이투솔루션의 CI</h6>
                    </div>
                    <div className="ci">
                        <div className="ci_logo">
                            <img src={require('../resources/images/ci_logo.gif')} alt="kks-ci-logo" />
                        </div>
                        <img
                            src={require('../resources/images/ci_kks.jpg')}
                            alt="kks-ci-1"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                        />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default About
