// import { useState } from 'react'

// type JsonFetchOptions = {
//     method?: string
//     body?: string
// }
// type FormFetchOptions = {
//     method: string
//     body: FormData
// }
// type FetchCallBacks = {
//     successCallback?: (data: any) => void
//     failureCallback?: (message: string) => void
// }
// const useFetch = (label?:string) => {
//     const [loading, setLoading] = useState(false)

//     const jsonResponse = async (path: string, options?: JsonFetchOptions, callback?: FetchCallBacks) => {
//         const fetchOptions: RequestInit | undefined = {
//             method: options?.method ?? 'GET',
//             headers: {
//                 'content-type': 'application/json',
//             },
//             body: options?.body,
//         }
//         await getResponse(path, fetchOptions, callback)
//     }

//     async function formResponse(path: string, options?: FormFetchOptions, callback?: FetchCallBacks) {
//         const fetchOptions: RequestInit | undefined = {
//             method: options?.method ?? 'GET',
//             body: options?.body,
//         }
//         await getResponse(path, fetchOptions, callback)
//     }

//     async function getResponse(path: string, fetchOption: RequestInit, callback?: FetchCallBacks) {
//         setLoading(true)
//         try {
//             let response = await fetch(`${process.env.REACT_APP_URL}${path}`, fetchOption)

//             if (!response.ok) {
//                 if (callback?.failureCallback) {
//                     callback?.failureCallback(`response error status : ${response.status}`)
//                 }
//             }
//             const json = await response.json()
//             if (callback) {
//                 if (json.result) {
//                     if (callback.successCallback) {
//                         callback.successCallback(json.data)
//                     }
//                 } else {
//                     if (callback.failureCallback) {
//                         callback.failureCallback(json.message)
//                     }
//                 }
//             }
//         } catch (error) {
//             if (callback?.failureCallback) {
//                 callback?.failureCallback(`${error}`)
//             }
//         } finally {
//             setLoading(false)
//         }
//     }
//     async function downloadResponse(url : string , name : string) {

//         const response = await fetch(`${url}`)
//         if(!response.ok){
//             alert(`${name} 파일을 다운로드 받을 수 없습니다. 자세한 원인은 콘솔창을 확인 해 주세요.`)
//             console.warn(response.statusText)
//             return
//         }
//         const blob = await response.blob()
//         const a = document.createElement('a')
//         a.href = URL.createObjectURL(blob)
//         a.style.display = 'none'
//         a.download = name
//         document.body.appendChild(a)
//         a.click()
//         a.remove()
//     }

//     const loadingContainer = loading && <div className="loading-pannel"></div>
//     const submitButton = loading ?<button type='button' disabled></button>: <button type='submit'>{label}</button>
//     return { submitButton,loadingContainer, jsonResponse, formResponse , downloadResponse }
// }

// export default useFetch

import { useState } from 'react'
import { FetchOption } from '../types/global'
import Loading from '../components/Loading'

const useFetch = (label?: string) => {
    const [loading, setLoading] = useState<boolean>(false)

    const getJson = (path: string, options?: FetchOption): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            setLoading(true)
            const url = process.env.REACT_APP_URL + path

            const option: FetchOption = {
                method: options?.method || 'GET',
                headers: {
                    'content-type': 'application/json',
                },
                body: options?.body,
            }

            try {
                const response = await fetch(url, option)

                if (response.status !== 200) {
                    reject(`response error \nstatus : ${response.status} \nmessage : ${response.statusText}`)
                }
                const json = await response.json()

                if (!json.result) {
                    reject(json.message)
                }
                resolve(json.data)
            } catch (error) {
                console.error(error)
            } finally {
                setLoading(false)
            }
        })
    }
    const getForm = (path: string, options?: FetchOption): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            setLoading(true)
            const url = process.env.REACT_APP_URL + path

            const option: FetchOption = {
                method: options?.method || 'GET',
                headers: {},
                body: options?.body,
            }

            try {
                const response = await fetch(url, option)

                if (response.status !== 200) {
                    reject(`response error \nstatus : ${response.status} \nmessage : ${response.statusText}`)
                }
                const json = await response.json()

                if (!json.result) {
                    reject(json.message)
                }
                resolve(json.data)
            } catch (error) {
                console.error(error)
            } finally {
                setLoading(false)
            }
        })
    }
    const getBlob = (path: string, options?: FetchOption): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            setLoading(true)
            const url = process.env.REACT_APP_URL + path

            const option: FetchOption = {
                method: options?.method || 'GET',
                headers: {},
                body: options?.body,
            }
            try {
                const response = await fetch(url)

                if (response.status !== 200) {
                    reject(`response error \nstatus : ${response.status} \nmessage : ${response.statusText}`)
                }
                const blob = await response.blob()
                resolve(blob)
            } catch (error) {
                console.error(error)
            } finally {
                setLoading(false)
            }
        })
    }

    const loadingContainer = <Loading loading={loading} />
    const submitButton = loading ? <button type="button" disabled></button> : <button type="submit">{label}</button>
    return { getJson, getForm, getBlob, loadingContainer, loading, submitButton }
}

export default useFetch
