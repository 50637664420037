import Layout from '../layouts/Layout'

const Private = () => {
    return (
        <Layout showHeader>
            <div className="sub private">
                <h1>개인정보 처리방침</h1>

                <h2>수집하는 개인정보</h2>
                <p>케이투솔루션은 다음과 같은 개인정보를 수집합니다:</p>
                <ul>
                    <li>이름</li>
                    <li>연락처 (전화번호 또는 휴대전화 번호)</li>
                    <li>이메일 주소</li>
                </ul>

                <h2>개인정보의 수집 목적</h2>
                <p>케이투솔루션은 수집한 개인정보를 다음 목적을 위해 사용합니다:</p>
                <ul>
                    <li>서비스 제공 및 운영</li>
                    <li>고객 지원 및 문의 응대</li>
                    <li>이벤트 및 프로모션 알림</li>
                    <li>서비스 개선 및 유지보수</li>
                    <li>법적 의무의 이행</li>
                </ul>

                <h2>개인정보의 보호 조치</h2>
                <p>
                    케이투솔루션은 개인정보를 보호하기 위해 적절한 보안 조치를 시행하며, 개인정보 보호 관련 법령 및
                    지침을 준수합니다.
                </p>

                <h2>이용자의 권리</h2>
                <p>
                    이용자는 언제든지 자신의 개인정보에 대한 열람, 정정, 삭제를 요구할 수 있습니다. 또한 개인정보 처리에
                    대한 동의 철회 또는 처리의 제한을 요청할 수 있습니다.
                </p>

                <h2>개인정보 관리책임자</h2>
                <p>케이투솔루션의 개인정보 관리책임자는 신중길입니다.</p>

                <p>본 개인정보 처리방침은 2024년 2월 1일에 최근 업데이트되었습니다.</p>
            </div>
        </Layout>
    )
}

export default Private
