import ContactForm from '../components/ContactForm'
import Layout from '../layouts/Layout'

const Contact = () => {
    return (
        <Layout showHeader={true}>
            <section id="contact">
                <div className="contact_banner">
                    <h1>create with kksolution</h1>
                </div>
                <div className="wrapper">
                    <ContactForm />
                    <div className="contact-quick">
                        <div className="contact_img">
                            <img src={require('../resources/images/sub/q_img.jpg')} />
                        </div>
                        <dl>
                            <div>
                                <dt>
                                    <img src={require('../resources/icons/mail.png')} />
                                    EMAIL
                                </dt>
                                <dd>
                                    <a href="mailto:cs@kksolution.co.kr">cs@kksolution.co.kr</a>
                                </dd>
                            </div>
                            <div>
                                <dt>
                                    <img src={require('../resources/icons/call_black.png')} />
                                    TEL
                                </dt>
                                <dd>
                                    <a href="tel:051-816-1470">051-816-1470</a>
                                </dd>
                            </div>
                            <div>
                                <dt>
                                    <img src={require('../resources/icons/phone.png')} />
                                    PHONE
                                </dt>
                                <dd>
                                    <a href="tel:010-5842-3470">010-5842-3470</a>
                                </dd>
                            </div>
                            <div>
                                <dt>
                                    <img src={require('../resources/icons/location.png')} />
                                    ADDRESS
                                </dt>
                                <dd>부산시 부산진구 서면로 10 114호</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contact
