import { FC, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { usePath } from '../hooks/usePath'
import useFormData from '../hooks/useFormData'
import useFetch from '../hooks/useFetch'
type Props = {
    showQucikContact?: boolean
}
const Footer: FC<Props> = ({ showQucikContact }) => {
    const { Forward } = usePath()
    const recaptchaRef = useRef<ReCAPTCHA>(null)
    const { formData, changeHandler, updateFormData, initFormData } = useFormData({
        companyName: '',
        managerName: '',
        managerContact: '',
        content: '',
        captchaToken: '',
    })
    const { getForm, loadingContainer, getBlob } = useFetch()
    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()

        if (!formData.captchaToken) {
            alert('자동입력방지를 실행 해 주세요.')
            return
        }
        const fetchBody = new FormData()
        fetchBody.append('companyName', formData.companyName)
        fetchBody.append('managerName', formData.managerName)
        fetchBody.append('managerContact', formData.managerContact)
        fetchBody.append('captchaToken', formData.captchaToken)
        fetchBody.append('content', formData.content)
        getForm(`/v1/inquiry`, {
            method: 'POST',
            body: fetchBody,
        })
            .then((_data) => {
                alert('문의가 등록되었습니다 . \n빠른 시일 내에 연락 드리겠습니다. \n감사합니다.')
                initFormData()
                recaptchaRef.current?.reset()
            })
            .catch((_message) => {
                alert(_message)
                initFormData()
                recaptchaRef.current?.reset()
            })
        // formResponse(
        //     `/v1/inquiry`,
        //     {
        //         method: 'POST',
        //         body: fetchBody,
        //     },
        //     {
        //         successCallback: (data) => {
        //             alert('문의가 등록되었습니다 . \n빠른 시일 내에 연락 드리겠습니다. \n감사합니다.')
        //             initFormData()
        //             recaptchaRef.current?.reset()
        //         },
        //         failureCallback: (message) => {
        //             alert(message)
        //             initFormData()
        //             recaptchaRef.current?.reset()
        //         },
        //     },
        // )
    }

    return (
        <footer>
            {showQucikContact && (
                <div className="top">
                    <div className="f_top_left">
                        <div className="ment">
                            <h2>지금, 함께 새로운 시작.</h2>
                            <h4>One day or day one. You decide.</h4>
                        </div>
                        <div className="buttons">
                            <button
                                onClick={() => {
                                    getBlob('https://kr.object.ncloudstorage.com/kksolution/kksolution/KKSolution.pdf')
                                        .then((_blob) => {
                                            const a = document.createElement('a')
                                            a.href = URL.createObjectURL(_blob)
                                            a.style.display = 'none'
                                            a.download = '케이투솔루션 회사소개서.pdf'
                                            document.body.appendChild(a)
                                            a.click()
                                            a.remove()
                                        })
                                        .catch((_message) => {
                                            alert(_message)
                                        })
                                    // downloadResponse(
                                    //     'https://kr.object.ncloudstorage.com/kksolution/kksolution/KKSolution.pdf',
                                    //     '케이투솔루션 회사소개서.pdf',
                                    // )
                                }}
                            >
                                <img src={require('../resources/icons/download.png')} alt="download" />
                                회사소개서 다운로드
                            </button>
                            <Forward to="/contact">
                                <img src={require('../resources/icons/qna.png')} alt="qna" />
                                견적문의
                            </Forward>
                        </div>
                    </div>
                    <div className="f_top_right">
                        <div className="quick_form">
                            <h6>빠른 상담/견적문의</h6>
                            <form onSubmit={submitHandler} className="form">
                                <label className="input-wrapper">
                                    <span className="label">
                                        <span>업체명</span>
                                    </span>
                                    <span className="input">
                                        <input
                                            type="text"
                                            name="companyName"
                                            value={formData.companyName}
                                            onChange={changeHandler}
                                            autoComplete="off"
                                            required
                                        />
                                    </span>
                                </label>
                                <label className="input-wrapper">
                                    <span className="label">
                                        <span>담당자명</span>
                                    </span>
                                    <span className="input">
                                        <input
                                            type="text"
                                            name="managerName"
                                            value={formData.managerName}
                                            onChange={changeHandler}
                                            autoComplete="off"
                                            required
                                        />
                                    </span>
                                </label>
                                <label className="input-wrapper">
                                    <span className="label">
                                        <span>담당자연락처</span>
                                    </span>
                                    <span className="input">
                                        <input
                                            type="text"
                                            name="managerContact"
                                            value={formData.managerContact}
                                            onChange={changeHandler}
                                            autoComplete="off"
                                            required
                                        />
                                    </span>
                                </label>
                                <label className="input-wrapper">
                                    <span className="label">
                                        <span>문의내용</span>
                                    </span>
                                    <span className="input">
                                        <textarea
                                            name="content"
                                            value={formData.content}
                                            onChange={changeHandler}
                                            autoComplete="off"
                                        />
                                    </span>
                                </label>
                                <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-around' }}>
                                    <ReCAPTCHA
                                        style={{
                                            display: 'grid',
                                            justifyContent: 'center',
                                            marginBottom: 10,
                                            marginTop: 10,
                                        }}
                                        ref={recaptchaRef}
                                        size="compact"
                                        sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY || ''}
                                        onChange={(token) => {
                                            updateFormData({ ...formData, captchaToken: token || '' })
                                        }}
                                    />
                                    <button type="submit">
                                        <img src={require('../resources/icons/arrow_black.png')} alt="arrow" />
                                        문의하기
                                    </button>
                                </div>
                            </form>
                            {loadingContainer}
                        </div>
                    </div>
                </div>
            )}

            <div className="bottom">
                <div className="logo">
                    <img src={require('../resources/images/f_logo.png')} alt="kksolution" />
                </div>
                <div className="company">
                    <dl>
                        <dt>ABOUT</dt>
                        <dd>주소 | 부산시 부산진구 서면로 10 서면 데시앙스튜디오 1층 114호</dd>

                        <dd>사업자등록번호 | 245-13-02668</dd>
                        <dd>통신판매업신고번호 | 2024-부산진-0324호</dd>
                    </dl>
                    <dl>
                        <dt>CS</dt>
                        <dd>T 051-816-1470</dd>
                        <dd>H 010-5842-3470</dd>
                        <dd>E cs@kksolution.co.kr</dd>
                        <dd>월-금 09:30 - 18:30</dd>
                        <dd>주말 및 공휴일 휴무</dd>
                    </dl>
                </div>
                <div className="sns">
                    <dl>
                        <dt>SNS</dt>
                        <dd>
                            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/kksolution2024/">
                                <img src={require('../resources/images/insta.png')} alt="instagram" />
                            </a>
                        </dd>

                        <dd>
                            <a target="_blank" rel="noreferrer" href="https://pf.kakao.com/_FwxdxmG">
                                <img src={require('../resources/images/kakao.png')} alt="kakao" />
                            </a>
                        </dd>
                        <dd>
                            <a target="_blank" rel="noreferrer" href="https://blog.naver.com/kksolution-">
                                <img src={require('../resources/images/blog.png')} alt="blog" />
                            </a>
                        </dd>
                        <dd>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://youtube.com/@user-si7xk1sw6l?si=Xr1cQHzAZqe7ILiX"
                            >
                                <img src={require('../resources/images/youtube.png')} alt="blog" />
                            </a>
                        </dd>
                    </dl>
                </div>
            </div>

            <div className="sub-menu">
                <Forward to="/privacy">개인정보처리방침</Forward>
            </div>
            <div className="copyright">CopyRight &copy; 2024 KKSolution All Rights Reserved.</div>
        </footer>
    )
}
export default Footer
