import { FC, ReactNode, useContext, useEffect, useState } from 'react'
import { ModalOptions } from '../types/global'

type Props = {
    option: ModalOptions
    closeHandler: () => void
}
const Modal: FC<Props> = ({ option, closeHandler }) => {
    return (
        <div id="modal" onClick={closeHandler}>
            <div className="modal-wrapper" style={{}} onClick={(e: React.MouseEvent) => e.stopPropagation()}>
                <button className="modal-close" onClick={(e)=>{
e.preventDefault()
document.body.classList.remove('active-modal')
                }}>
                    <img src="/svg/icon_close.svg" />
                </button>
                <div className="modal-content">{option.element}</div>
            </div>
        </div>
    )
}

export default Modal
