import React, { useRef, useState } from 'react'

import useFormData from '../hooks/useFormData'
import useFetch from '../hooks/useFetch'
import ReCAPTCHA from 'react-google-recaptcha'

const ContactForm = () => {
    const { getForm, loadingContainer, submitButton } = useFetch('문의하기')
    const recaptchaRef = useRef<ReCAPTCHA>(null)
    const { formData, updateFormData, changeHandler, initFormData } = useFormData({
        companyName: '',
        managerName: '',
        managerContact: '',
        managerEmail: '',
        content: '',
        captchaToken: '',
    })
    const [upFiles, setUpFiles] = useState<File[]>([])
    const maxFileCount = 5
    const maxFileSize = 10 * 1024 * 1024
    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()

        if (!formData.captchaToken) {
            alert('자동입력방지를 실행 해 주세요.')
            return
        }
        const fetchBody = new FormData()
        fetchBody.append('companyName', formData.companyName)
        fetchBody.append('managerName', formData.managerName)
        fetchBody.append('managerContact', formData.managerContact)
        fetchBody.append('managerEmail', formData.managerEmail)
        fetchBody.append('captchaToken', formData.captchaToken)
        fetchBody.append('content', formData.content)
        upFiles.forEach((file) => {
            fetchBody.append('upFiles', file)
        })
        getForm('/v1/inquiry', {
            method: 'POST',
            body: fetchBody,
        })
            .then((_data) => {
                alert('문의가 등록되었습니다 . \n빠른 시일 내에 연락 드리겠습니다. \n감사합니다.')
                initFormData()
                recaptchaRef.current?.reset()
                setUpFiles([])
            })
            .catch((_message) => {
                alert(_message)
                initFormData()
                recaptchaRef.current?.reset()
                setUpFiles([])
            })
        // formResponse(
        //     '/v1/inquiry',
        //     {
        //         method: 'POST',
        //         body: fetchBody,
        //     },
        //     {
        //         successCallback: (data) => {
        //             alert('문의가 등록되었습니다 . \n빠른 시일 내에 연락 드리겠습니다. \n감사합니다.')
        //             initFormData()
        //             recaptchaRef.current?.reset()
        //             setUpFiles([])
        //         },
        //         failureCallback: (message) => {
        //             alert(message)
        //             initFormData()
        //             recaptchaRef.current?.reset()
        //             setUpFiles([])
        //         },
        //     },
        // )
    }

    const fileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target
        if (!files?.length) return
        let fileLength = upFiles.length
        let fileSize = 0
        upFiles.forEach((upFile) => {
            fileSize += upFile.size
        })

        for (let i = 0; i < files?.length; i++) {
            const file = files[i]
            fileSize += file.size
            fileLength++

            if (fileLength > maxFileCount) {
                alert('최대 5개 등록 가능합니다.')
                break
            }
            if (fileSize > maxFileSize) {
                alert('최대 30MB 업로드 가능합니다.')
                break
            }
            setUpFiles((prev) => [...prev, file])
        }
        e.target.value = ''
    }
    const [dragFile, setDragFile] = useState<boolean>(false)
    const fileWrapperDragOverHandler = (e: React.DragEvent<any>) => {
        e.preventDefault()
        setDragFile(true)
    }
    const fileWrapperDragLeaveHandler = (e: React.DragEvent<any>) => {
        // e.preventDefault()
        setDragFile(false)
    }
    const deleteFileHandler = (index: number) => {
        setUpFiles(
            upFiles.filter((_, _index) => {
                return index !== _index
            }),
        )
    }

    return (
        <form className="form contact" onSubmit={submitHandler}>
            <h1>ContactUs</h1>
            <h1>프로젝트에 대해 알려주세요.</h1>
            <h3>예산걱정은 뒤로 미루시고, 전문가의 상담을 먼저 받아보세요.</h3>
            <p>아래 내용을 등록해 주시면 견적서 및 제안서를 회신 드립니다.</p>
            <p>
                ※ 별도의 파일 및 대용량 파일 전송의 경우 cs@kksolution.co.kr 메일로 보내주시면 확인 후 피드백 드리도록
                하겠습니다.
            </p>

            <div>
                <div className="form-group">
                    <label className="input-wrapper">
                        <span className="label">
                            <span>업체명</span>
                        </span>
                        <span className="input">
                            <input
                                type="text"
                                name="companyName"
                                value={formData.companyName}
                                onChange={changeHandler}
                                autoComplete="off"
                                required
                            />
                        </span>
                    </label>
                    <label className="input-wrapper">
                        <span className="label">
                            <span>담당자명</span>
                        </span>
                        <span className="input">
                            <input
                                type="text"
                                name="managerName"
                                value={formData.managerName}
                                onChange={changeHandler}
                                required
                                autoComplete="off"
                            />
                        </span>
                    </label>
                    <label className="input-wrapper">
                        <span className="label">
                            <span>담당자 연락처</span>
                        </span>
                        <span className="input">
                            <input
                                type="text"
                                name="managerContact"
                                value={formData.managerContact}
                                onChange={changeHandler}
                                required
                            />
                        </span>
                    </label>
                    <label className="input-wrapper">
                        <span className="label">
                            <span>담당자 이메일</span>
                        </span>
                        <span className="input">
                            <input
                                type="email"
                                name="managerEmail"
                                value={formData.managerEmail}
                                onChange={changeHandler}
                            />
                        </span>
                    </label>
                    <label className="input-wrapper textarea">
                        <span className="label">
                            <span>프로젝트 내용</span>
                        </span>
                        <span className="input">
                            <textarea
                                name="content"
                                onChange={changeHandler}
                                placeholder="원하시는 서비스의 내용을 기입해주세요. ex) 반응형 홈페이지 제작"
                                value={formData.content}
                            />
                        </span>
                    </label>
                    <label className="input-wrapper file">
                        <span className="label">
                            <span>첨부파일</span>
                        </span>
                        <label
                            className={`file ${dragFile ? 'active' : ''}`}
                            onDragOver={fileWrapperDragOverHandler}
                            onDragLeave={fileWrapperDragLeaveHandler}
                            onDrop={fileWrapperDragLeaveHandler}
                        >
                            <span>
                                파일을 드래그하거나 <span>파일선택</span>
                            </span>
                            <input
                                type="file"
                                multiple
                                onChange={fileChangeHandler}
                                accept=".png,.jpg,.jpeg,.gif,.pdf,.zip"
                            />
                        </label>
                    </label>
                    <ul className="file-list">
                        {upFiles.map((file, index) => {
                            return (
                                <li key={index}>
                                    <span className="name">{file.name}</span>
                                    <span className="size">({file.size}KB)</span>
                                    <span
                                        className="delete"
                                        onClick={() => {
                                            deleteFileHandler(index)
                                        }}
                                    >
                                        삭제
                                    </span>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <ReCAPTCHA
                    style={{ display: 'grid', justifyContent: 'center', marginBottom: 10 }}
                    ref={recaptchaRef}
                    size="normal"
                    sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY || ''}
                    onChange={(token) => {
                        updateFormData({ ...formData, captchaToken: token || '' })
                    }}
                />
                <div className="buttons">{submitButton}</div>
                {/* {loadingContainer} */}
            </div>
        </form>
    )
}

export default ContactForm
